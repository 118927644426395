@use "styles" as *;

.sideNavLink {
  padding: 15px 20px;
  border-radius: 5px;
  color: color(powerful-white);
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  align-items: center;

  &.active,
  &:hover {
    background-color: color(powerful-secondary);
  }

  .sideNavLink__svg {
    font-size: 1.5rem;
  }

  .sideNavLink__text {
    margin-left: 10px;
  }
}
