@use "styles" as *;

.formCheckbox__checkbox {
  display: flex;
  align-items: center;
  width: fit-content;
  

  

  .formCheckbox__input {
    border: 1px solid color(powerful-secondary);
    appearance: none;
    width: 20px;
    border: 1px solid color(powerful-primary);
    height: 10px;
    border-radius: 2px;
    background-color: #fff;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    padding: 8px;

    @media (max-width: 950px) {
      width: 15px;

    }

    &:checked {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

      &:after {
        opacity: 1;
      }
    }

    &:after {
      position: absolute;
      content: "\2714";
      top: 48%;
      left: 50%;
      transform: translate(-50%, -53%) rotate(10deg);
      color: color(powerful-secondary);
      transition: 0.3s;
      opacity: 0;
    }
  }

  
}
