@use "styles" as *;

.mainContent {
  margin-left: 250px;

  .mainContent__container {
    width: 90%;
    margin: 28px auto 0;
    padding-bottom: 40px;

    &.fixed {
      height: calc(100vh - 120px);
      padding-bottom: 5px;
    }
  }
}
