@use "styles" as *;

.statusAmount {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  border: 1px solid color(powerful-secondary);
  transition: 0.2s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: color(powerful-secondary);

    .statusAmount__title,
    .statusAmount__amount {
      color: color(powerful-white);
    }
  }

  .statusAmount__title {
    margin-right: 5px;
    font-weight: 600;
    color: color(powerful-secondary);
  }

  .statusAmount__amount {
    color: color(powerful-secondary);
  }
}
