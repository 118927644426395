.formNavigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.7);
    margin-left: 20px;
    cursor: pointer;
  }
}

.stepper {
  display: flex;
  justify-content: space-around;

  .stepper__div {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;

    &.active {
      color: tomato;
    }
  }
}
