@use "styles" as *;

.switch {
  // width: 50px;
  // height: 25px;
  border-radius: 30px;
  position: relative;
  // background-color: #bfbfbf;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &.active {
    .switch__body {
      background-color: color(powerful-white);

      &:before {
        //   left: 27px;
        background-color: color(powerful-secondary);
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px transparentize(color(powerful-secondary), 0.3);
    }
  }

  &:active {
    .switch__body {
      &:before {
        width: 28px;
      }
    }
  }

  .switch__checkbox {
    position: absolute;
    width: 1px;
    opacity: 0;

    &:focus {
      + .switch__body {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
      }

      &:checked {
        + .switch__body {
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
      }
    }
  }

  .switch__body {
    width: 50px;
    height: 25px;
    border-radius: 30px;
    position: relative;
    background-color: #bfbfbf;
    display: inline-block;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:before {
      width: 21px;
      height: 21px;
      background-color: white;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      content: "";
      bottom: 2px;
      position: absolute;
      transition: 0.3s ease-in-out;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }
  }

  .switch__label {
    color: color(powerful-white);
    margin-left: 10px;
  }
}
