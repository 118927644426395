@use "styles" as *;

.paginationBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

}
.paginationAction {
  display: flex;
  gap:10px;
  &>p {
    color: color(powerful-secondary);
    align-self: center;
    border: 1px solid color(powerful-secondary);
    padding: 10px 20px;
    border-radius: 5px;
  }
  &>button {
padding: 10px 20px;
align-items: center;
background-color: color(powerful-secondary);
color: #fff;
border-radius: 5px;
outline: none;
border: none;
cursor: pointer;
&:disabled{
  opacity: 0.3;
  &:hover {
    opacity: .3;
    cursor:not-allowed
  }
}
&:hover{
  opacity: 0.8;
}
  }
}