.formCheckbox {
  .formCheckbox__checkbox {
    display: flex;
    align-items: center;
  }

  .formCheckbox__input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    &:checked {
      background-color: rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);

      &:after {
        color: white;
        opacity: 1;
      }
    }

    &:after {
      position: absolute;
      content: "\2714";
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  .formCheckbox__label {
    cursor: pointer;
  }
}
