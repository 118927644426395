.linkTag {
  text-decoration: none;
  color: black;
  font-weight: 600;
  transition: 0.3s;

  &:hover {
    color: red;
  }
}
