@use "styles" as *;

.sendMsgForm {
  width: 100%;
  .editor {
    background-color: #ffffff;
    margin-bottom:  3rem;
  }
  .custom {
    color:#ffffff;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 0.75rem;
  }
  .label {
    font-size: 1.3rem;
    color: #ffffff;
  }

  .sendMsgForm__field {
    margin-bottom: 15px;
  }

  .usersModal {
    margin-bottom: 15px;
    display: none;

    &.show {
      display: block;
    }
  }

  .usersModal__label {
    margin-bottom: 5px;
    color: color(powerful-white);
  }

  .usersModal__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background-color: color(powerful-primary-dark);
  }

  .usersModal__text {
    color: color(powerful-white);
  }

  .usersModal__svg {
    svg {
      color: color(powerful-white);
    }
  }

  .usersModal__modal {
    background-color: color(powerful-white);
    border-radius: 10px;
    width: 500px;
    padding: 20px;
    min-height: 400px;
  }

  .usersModal__modalHeader {
    margin-bottom: 20px;
  }

  .usersModal__inputDiv {
    margin-bottom: 10px;

    input {
      outline: none;
      border: 1px solid color(powerful-primary);
      padding: 10px 15px;
      border-radius: 5px;
      width: 100%;
      // font-size: 1rem;

      &::placeholder {
        font-family: "Inter", sans-serif;
      }
    }
  }

  .usersModal__btn {
    margin-bottom: 10px;
    display: flex;
    gap: 15px;
  }
  .input {
    outline: none;
    border: 1px solid color(powerful-primary);
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
    // font-size: 1rem;
    margin-bottom: 1rem;

    &::placeholder {
      font-family: "Inter", sans-serif;
    }
  }
  .formCheckboxGroup__input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid color(powerful-secondary);
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    &:checked {
      background-color: color(powerful-secondary);
      box-shadow: 0 0 2px color(powerful-secondary);

      &:after {
        color: white;
        opacity: 1;
      }
    }

    &:after {
      position: absolute;
      content: "\2714";
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  .channels {
    display: flex;
    flex-direction: column;
    color:#ffffff
  }
}
