.formDropdown {
  position: relative;

  .formDropdown__head {
    padding: 5px 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .formDropdown__dropdown {
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    display: none;
    background-color: white;

    &.active {
      display: block;
    }
  }

  .formDropdown__radio {
    display: flex;
    align-items: center;
  }

  .formDropdown__input {
    display: none;
  }

  .formDropdown__label {
    cursor: pointer;
    width: 100%;
    padding: 5px 10px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    transition: 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
