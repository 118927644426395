@use  "styles/index" as *;

.alert {
    width: 100%;
    padding: 10;
    border-radius: 10;
    display: flex;
    align-items: center;
    &__message {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 16px;
    }
    &__message--error {
        color: color(powerful-danger)
    }
}