@use "styles" as *;

.skeleton {
  width: 100%;
  min-height: 100px;
  animation: animateSkeleton 1.5s infinite;
  margin-bottom: 10px;
}

@keyframes animateSkeleton {
  0% {
    background-color: transparentize(color(powerful-primary-dark), 0.3);
  }
  50% {
    background-color: transparentize(color(powerful-primary-dark), 0.8);
  }
  100% {
    background-color: transparentize(color(powerful-primary-dark), 0.3);
  }
}
