@use "styles" as *;

.sectionHeader {
  font-size: 1.6875rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-bottom: 80px;

  @include maxWidth(767px) {
    font-size: 1.4rem;
    margin-bottom: 50px;
  }
}
