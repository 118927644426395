@use "styles" as *;

.h4 {
  font-size: 1.25rem;
  font-weight: 500;

  @include maxWidth(767px) {
    font-size: 1rem;
  }
}
