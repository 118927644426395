@use "styles" as *;

.notificationRow {
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(7, 2fr);
  gap: 15px;
  padding: 15px 15px;
  background-color: color(powerful-primary-dark);
  margin-bottom: 10px;
  cursor: pointer;

  &.condition {
    grid-template-columns: repeat(7, 1fr);
  }

  &.failed {
    border-left: 2px solid color(powerful-danger);

    .notificationRow__item {
      &:last-child {
        color: color(powerful-danger);
      }
    }
  }

  &.success {
    border-left: 2px solid color(powerful-success);

    .notificationRow__item {
      &:last-child {
        color: color(powerful-success);
      }
    }
  }

  &.pending {
    border-left: 2px solid color(powerful-warning);

    .notificationRow__item {
      &:last-child {
        color: color(powerful-warning);
      }
    }
  }

  .notificationRow__item {
    color: color(powerful-white);
    word-break: break-all;
  }
}
.requery-btn {
  background-color: #eb7b44;;
  outline: none;
  border-radius: 10px;
  color: white;
  border:none
}
.refundBtn {
  background-color: transparent;
  border: 1px solid color(powerful-secondary);
  &:hover {
    background-color: color(powerful-secondary)
  };
  .row {
    display: flex;
    align-items: center;
  }
}
