*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent; // to remove the bluish background on click
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}
