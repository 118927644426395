@use "styles" as *;

$btnVariants: primary, secondary, primaryOutline, secondaryOutline;

$btnValues: (
  primary: (
    background-color: color(powerful-primary),
    color: color(powerful-white),
    border: 1px solid color(powerful-primary),
  ),
  secondary: (
    background-color: color(powerful-secondary),
    color: color(powerful-white),
    border: 1px solid color(powerful-secondary),
  ),
  primaryOutline: (
    background-color: transparent,
    color: color(powerful-primary),
    border: 1px solid color(powerful-primary),
  ),
  secondaryOutline: (
    background-color: transparent,
    color: color(powerful-secondary),
    border: 1px solid color(powerful-secondary),
  ),
);

@function getBtnValue($name, $property) {
  @return map-get(map-get($btnValues, $name), $property);
}

.button {
  outline: none;
  border-radius: 5px;
  padding: 10px 30px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  transition: 0.3s;
  font-family: "Inter", sans-serif;

  @each $name in $btnVariants {
    &.button--#{$name} {
      background-color: getBtnValue($name, background-color);
      color: getBtnValue($name, color);
      border: getBtnValue($name, border);
    }
  }

  &.button--fullWidth {
    width: 100%;
    display: block;
  }

  &.button--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.button--loading {
    @extend .button--disabled;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
