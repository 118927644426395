.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 100%;
  z-index: 10;
  //   transition: 0.3s;

  &.active {
    left: 0;
  }

  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal__box {
    max-width: 90%;
    position: relative;
  }

  .modal__close {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    background-color: white;
    bottom: calc(100% + 3px);
    right: 0;
  }

  .modal__content {
    max-height: 85vh;
    overflow-y: auto;
  }
}
