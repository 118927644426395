@use "styles" as *;

.formInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .formInput__input {
    outline: none;
    border: 1px solid color(powerful-primary);
    padding: 10px 15px;
    border-radius: 5px;
    // font-size: 1rem;

    &::placeholder {
      font-family: "Inter", sans-serif;
    }
  }
}
