@use "styles" as *;

.h1 {
  font-size: 2.375rem;
  font-weight: bold;

  @include maxWidth(767px) {
    font-size: 1.975rem;
  }
}
