@use "styles" as *;
.updateWallet {
    margin-top: 2rem;
    margin-bottom: 2rem;
  max-width: 80%;
  &__heading {
    color: color(powerful-white);
  }
  & > form {
    margin-top: 2rem;
  }
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    
   
  }
  &__description {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
   
    &>select {
        width: 100%;
        height: 150px;
        padding: 10px;
    }
  }
  &__col {
    width: 48%;
    & > input {
      height: 55px;
      outline: none;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
    }
    & > select {
        height: 55px;
        outline: none;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
      }
  
  }
  & button {
    border-radius: 10px;
    margin-top: 2rem;
    height: 50px;
    width: 40%;
    font-weight: bold;
    font-size: 1.05rem;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
  }
  &__label {
    color: color(powerful-white);
    margin-bottom: 0.75rem;
  }
  &__forceUpdate {
    display: flex;
    margin-top: 1rem;
    color: color(powerful-white);
  }
}
