@use "styles" as *;

.sideNav {
  position: fixed;
  width: 250px;
  height: 100vh;
  padding: 15px;
  box-shadow: 0 0 3px color(powerful-gray);
  overflow: scroll;
  .sideNav__imgDiv {
    text-align: center;
    width: 180px;
    margin: 5px auto 0;
  }

  .sideNav__links {
    margin-top: 40px;
  }

  .sideNav__logout {
    padding: 15px 20px;
    border-radius: 5px;
    color: color(powerful-white);
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;

    &:hover {
      background-color: color(powerful-secondary);
    }

    svg {
      font-size: 1.5rem;
    }
  }

  .sideNav__logoutText {
    margin-left: 10px;
  }
}
