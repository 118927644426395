@use "styles" as *;

.formCheckboxGroup {
  .formCheckboxGroup__checkbox {
    display: flex;
    align-items: center;
  }

  .formCheckboxGroup__input {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid color(powerful-secondary);
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    &:checked {
      background-color: color(powerful-secondary);
      box-shadow: 0 0 2px color(powerful-secondary);

      &:after {
        color: white;
        opacity: 1;
      }
    }

    &:after {
      position: absolute;
      content: "\2714";
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  .formCheckboxGroup__label {
    cursor: pointer;

    &.white {
      color: color(powerful-white);
    }
  }
}
