@use "styles" as *;

.details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;

  .details__info {
    flex: 1;
  }

  .details__box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .details__title {
    margin-right: 10px;
    font-weight: 600;
    color: color(powerful-white);
  }

  .details__value {
    color: color(powerful-white);
    font-weight: 400;
  }

  .details__conditionTitle {
    margin-bottom: 5px;
    color: color(powerful-white);
  }

  .details__conditionRow {
    display: flex;
    align-items: center;
    max-width: 70%;
  }

  .details__select {
    background-color: color(powerful-primary-dark);
    color: color(powerful-white);
    padding: 11px;
    border-radius: 5px;
    flex: 1;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: 2px solid color(powerful-secondary-light);
    }
  }

  .details__wallet {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .details__walletHeader {
    margin-bottom: 15px;
    color: color(powerful-white);
  }

  .details__balance {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid color(powerful-secondary);
    color: color(powerful-secondary);
    width: 100%;
    text-align: center;
  }

  .details__form {
    margin-top: 20px;
    width: 100%;
  }
}
.actionBtns {
  gap:20px;
  &> .refundBtn {
    margin-left: 20px;
    background-color: transparent;
    &:hover {
      background-color: color(powerful-secondary);
    }
  }
}
.copyBtn {
  border: none;
  background-color: transparent;
  color: color(powerful-secondary);
  opacity: 0.5;
  &:hover{
    opacity: 1;
  }
}