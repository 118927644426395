@use "styles" as *;

.notificationTable {
  .notificationTable__head {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
    padding: 15px 15px;
    background-color: color(powerful-primary-dark);
    margin-bottom: 10px;
    cursor: pointer;

    &.condition {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  .notificationTable__headItem {
    color: color(powerful-white);
  }

  .notificationTable__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  .notificationTable__emptyIcon {
    font-size: 3rem;
    color: color(powerful-secondary);
  }

  .notificationTable__emptyText {
    color: color(powerful-white);
  }
  &> .tableActions {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
    gap: 10px;
    justify-content: flex-end;
    .startBtn {
      background-color: #eb7b44;;
      outline: none;
      border-radius: 10px;
      color: white;
      border:none;
      padding: 10px 30px;
      opacity: 0.9;
      &:hover{
        opacity: 1;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    .achiveBtn {
      background-color: transparent;
      border: 1px solid color(powerful-secondary);
      border-radius: 10px;
      color: white;
      padding: 10px 30px;
      &:hover {
        background-color: color(powerful-secondary)
      };
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
  }
  .notificationRow {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(7, 2fr);
    gap: 15px;
    padding: 15px 15px;
    background-color: color(powerful-primary-dark);
    margin-bottom: 10px;
    cursor: pointer;
  
    &.condition {
      grid-template-columns: repeat(7, 1fr);
    }
  
    &.failed {
      border-left: 2px solid color(powerful-danger);
  
      .notificationRow__item {
        &:last-child {
          color: color(powerful-danger);
        }
      }
    }
  
    &.success {
      border-left: 2px solid color(powerful-success);
  
      .notificationRow__item {
        &:last-child {
          color: color(powerful-success);
        }
      }
    }
  
    &.pending {
      border-left: 2px solid color(powerful-warning);
  
      .notificationRow__item {
        &:last-child {
          color: color(powerful-warning);
        }
      }
    }
  
    .notificationRow__item {
      color: color(powerful-white);
      word-break: break-all;
    }
    .notificationRow__item.success {
      color: color(powerful-success);
      word-break: break-all;
    }
  }
 
}

.success {
  color: color(powerful-success);
}
.users__broadcast {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
}

.users__form .smidForm {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 250px;
  gap: 1rem;
  padding: 1.5rem;
 & > input {
  border:  1px solid #ccc;
  padding: 1rem;
  outline: none;
  }
  & > button {
    margin-top: 2rem;
  }
}