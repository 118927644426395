@use "styles" as *;

.mainHeader {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  .mainHeader__container {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    background-color: color(powerful-primary);
    border-bottom: 1px solid color(powerful-gray);
  }

  .mainHeader__text {
    color: color(powerful-white);
    font-weight: 300;
  }
}
