@use "styles" as *;

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: color(whiteColor);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  &.remove {
    animation: removeLoader 1s forwards;
  }

  @keyframes removeLoader {
    0% {
      opacity: 1;
      visibility: visible;
    }

    100% {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  .loader__imgDiv {
    position: relative;
    transform-style: preserve-3d;
    perspective: 200px;
  }

  .loader__img {
    animation: animateLoader 1s infinite ease-in-out alternate;
  }
}

@keyframes animateLoader {
  0% {
    transform: translate(-50px, 0) rotateY(0);
  }

  //   50% {
  //     transform: translate(0, 0) rotateY(360deg);
  //   }

  100% {
    transform: translate(50px, 0) rotateY(360deg);
  }
}
