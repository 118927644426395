@use "styles" as *;

.labelText {
  font-size: 1.1rem;
  margin-bottom: 5px;
  font-weight: 500;

  &.labelText--white {
    color: color(powerful-white);
  }

  &.labelText--black {
    color: color(powerful-dark);
  }
}
