@use "styles" as *;

.formRadio {
  .formRadio__radio {
    display: flex;
    align-items: center;
  }

  .formRadio__input {
    appearance: none;
    width: 17px;
    height: 17px;
    border: 1px solid color(powerful-secondary);
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    &:before {
      width: 80%;
      height: 80%;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      border-radius: 50%;
      transform: translate(-53%, -56%);
      transition: 0.2s;
      background-color: color(powerful-secondary);
    }

    &:checked {
      &:before {
        opacity: 1;
      }
    }
  }

  .formRadio__label {
    cursor: pointer;
    color: color(powerful-white);
  }
}
